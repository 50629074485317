span.littleSpace {
    margin-right: 10px;
}

span.headerRight {
    float: right;
}

span.headerCenter {
    width: 240px;
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -120px; /*half the size of width*/
}