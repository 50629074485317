body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #D4E7C5; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
	padding: 10px;
}
button.withImage {
	padding-top: 8px;
  padding-bottom: 8px;
}
button img {
  width: 15px;
  height: 15px;
}

div.bigButtonsDiv button {
  padding: 40px;
  margin: 20px;
}
div.bigButtonsDiv {
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

button {
  margin: 2px;
  border: 0px solid;
}

button.selected {
  background: rgb(177, 221, 177);
  border: 1px solid rgb(177, 221, 177);
  border-color: rgb(177, 221, 177);
}


/*

button {
  align-items: center;
  background-clip: padding-box;
  background-color: #f6fcf4;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #617953;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}



button:hover,
button:focus {
  color: #E1F0DA;
  background-color:#99BC85;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

button:hover {
  transform: translateY(-1px);
}



button.warning {
  background: lightcoral;
}
button.warning > p {
  margin: 0;
  font-size: x-small;
}*/