
.footer {
    left: 10px;
    right: 12px;
    position: absolute;
    bottom: 8px;
}


.footer input {
    width: 100%;
    margin: 0 -1px 0 -1px;
    padding: 0px;
}