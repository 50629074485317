table.gameView {
	border-collapse: collapse;
	width: 100%;
}
.gameView th, td {
	text-align: left;
	padding: 8px;
	border-bottom: 1px solid #ddd;
}
.gameView th {
	background-color: #f2f2f2;
	color: #333;
}
table.gameView tr.benchy {
	background-color: grey;
}

div.propertyParent {
	white-space: normal; 
	display: block;
}
.propertyList {
	margin-left: 5px;
	margin-right: 5px;
	display: inline-block;
	white-space:nowrap; 
}